<template>
    <div class="box">
        <div class="register boxone" @click="AcquireStudenlist()">获取学生名单</div>
        <div class="login boxone">加入教室</div>
        <div class="login boxone" @click="doConnect()">连接MQTT</div>
        <div class="login boxone">订阅</div>
        <div class="login boxone">发送随机答案</div>
        <div class="login boxone">断开连接</div>
        教室编号：<input type="text" v-model="classnum" />
        <div class="studentlist">
            <p>学生列表：</p>
            <div class="studentbox" v-for="item in StudentList" :key="item.id">
                <p>编号:{{item.id}}</p>
                <p>姓名:{{item.name}}</p>
                <p>选择答案：{{item.answer}}</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                StudentList: [],
                clientId: 'c89edcfd-3830-4238-b8a8-0bd4b43c20ca',
                deviceId: 'oqADO5n-GBZX8AJZ86JpRExN2Nxo',
                username: 'cmy',
                mqttusername: 'coreiothubcn.azure-devices.cn/oqADO5n-GBZX8AJZ86JpRExN2Nxo/api-version=2016-11-14',
                mqttpassword: 'SharedAccessSignature sr=coreiothubcn.azure-devices.cn%2Fdevices%2FoqADO5n-GBZX8AJZ86JpRExN2Nxo&sig=MNEMK8bz1mz9XmA0D7FwBmUUQA9FlqGZz0MXIsX67%2B4%3D&se=1607670524',
                accepterId: '491c87a3-8202-70cd-8f23-639675d7ad5c',
                classnum: '867326',
                client: ''
            }
        },
        methods: {
            AcquireStudenlist() {
                let student = [
                    { 'id': 1, 'answer': 'A', 'name': '小明' },
                    { 'id': 2, 'answer': 'B', 'name': '王明' },
                    { 'id': 3, 'answer': 'C', 'name': '李晓' },
                    { 'id': 4, 'answer': 'D', 'name': '王军' },
                    { 'id': 5, 'answer': 'B', 'name': '陈粒' },
                    { 'id': 6, 'answer': 'A', 'name': '周峰' },
                    { 'id': 7, 'answer': 'C', 'name': '王刚' },
                    { 'id': 8, 'answer': 'D', 'name': '陈伟' }
                ]
                this.StudentList = student
            },
            /* 连接MQTT */
            doConnect() {
                let classroom = this.classnum
                let des = this.deviceId
                let accs = this.accepterId
                // 连接选项
                let accepterIds = {
                    'directMethod': this.accepterId
                }
                let willmsg = 'devices/' + this.deviceId + '/messages/events/endpoint=coretopic&groupNum=' + classroom + '&deviceId=' + this.deviceId
                let willtopic = willmsg + '&$.to=' + JSON.stringify(accepterIds)
                let willcontent = {
                    'Answer': '已意外离线：' + this.deviceId,
                    'GroupAPI-Version': 0.0,
                    'GroupMemberNum': 0,
                    'Host-Software-Version': 0,
                    'MemberID': 0,
                    'Pincode': 0,
                    'typeId': 5,
                    'id': this.deviceId
                }
                let willpayload = JSON.stringify(willcontent)
                console.log('用户连接信息：' + this.deviceId)
                console.log('执行连接，接收者ID为：' + this.accepterId)
                console.log(willpayload)
                var options = {
                    connectTimeout: 4000, // 超时时间
                    // 认证信息
                    clientId: this.deviceId,
                    username: this.mqttusername,
                    password: this.mqttpassword,
                    timeout: 3,
                    useSSL: true,
                    cleanSession: true,
                    keepAliveInterval: 60,
                    reconnect: true,
                    mqttVersion: 4,
                    mqttVersionExplicit: true,
                    will: {
                        topic: willtopic,
                        payload: willpayload,
                        qos: 0,
                        retain: false
                    }
                }
                let url = 'wss://coreiothubcn.azure-devices.cn/$iothub/websocket?iothub-no-client-cert=true'
                var client = this.$mqtt.connect(url, options)
                var _this = this
                _this.client = client
                client.on('connect', function() {
                    console.log('连接服务器成功!!!!!!!!')
                    // 订阅主题，替换productKey和deviceName
                    client.subscribe('$iothub/methods/POST/directMethod/#', function(err) {
                        if (!err) {
                            console.log('主题订阅成功')
                            _this.send(des, 0, accs)
                        } else {
                            console.log('主题订阅失败')
                        }
                    })
                })
                client.on('error', (error) => {
                    console.log('连接失败:', error)
                })
                client.on('message', (topic, msg) => {
                    console.log(msg.toString())
                })
            },
            /* 发送消息 */
            send(msgs, typeid, accepter) {
                let msg = msgs
                let sendMsg = 'devices/' + this.deviceId + '/messages/events/endpoint=coretopic&groupNum=' + this.classnum + '&deviceId=' + this.deviceId
                const timestamp = Math.floor(Date.now() / 1000)
                if (typeid == 0) {
                    var msgconfig = {
                        'Action': 'IRS.Answer',
                        'Sender': this.name,
                        'Timestamp': timestamp,
                        'TypeId': typeid,
                        'Payload': {
                            'Answer': msg,
                            'GroupAPI-Version': 0.0,
                            'GroupMemberNum': 0,
                            'Host-Software-Version': 0,
                            'MemberID': 0,
                            'Pincode': 0
                        }
                    }
                } else {
                    var msgconfig = {
                        'Action': 'IRS.Answer',
                        'Sender': this.name,
                        'Timestamp': timestamp,
                        'TypeId': typeid,
                        'Payload': {
                            'Answer': msg,
                            'GroupAPI-Version': 0.0,
                            'GroupMemberNum': 0,
                            'Host-Software-Version': 0,
                            'MemberID': 0,
                            'Pincode': 0
                        }
                    }
                }
                let payload = JSON.stringify(msgconfig)
                let receiveId = {
                    'directMethod': accepter
                }
                let pubTopic = sendMsg + '&$.to=' + JSON.stringify(receiveId)
                this.client.publish(pubTopic, payload, { qos: 1 }, (error) => {
                    if (!error) {
                        console.log('发布内容为：' + pubTopic)
                        console.log('发布成功')
                    } else {
                        console.log('发布失败')
                    }
                })
            }
            /* 连接 */
            // Connect() {
            //    let classroom = this.classnum;
            //    let accepterIds = {
            //        "directMethod": accepterId
            //    };
            //    let willmsg = "devices/" + this.deviceId + "/messages/events/endpoint=coretopic&groupNum=" + classroom + "&deviceId=" + this.deviceId
            //    let willtopic = willmsg + "&$.to=" + JSON.stringify(accepterIds);
            //    let willcontent = {
            //        "Answer": '已意外离线：' + this.deviceId,
            //        "avatarUrl": '',
            //        "GroupAPI-Version": 0.0,
            //        "GroupMemberNum": 0,
            //        "Host-Software-Version": 0,
            //        "MemberID": 0,
            //        "Pincode": 0,
            //        "typeId": 5,
            //        "id": this.deviceId,
            //    }
            //    let willpayload = JSON.stringify(willcontent);
            //    console.log("用户连接信息：" + this.deviceId);
            //    console.log('执行连接，接收者ID为：' + accepterId);
            //    let options = {
            //        connectTimeout: 4000, // 超时时间
            //        // 认证信息
            //        clientId: this.clientID,
            //        username: this.mqttusername,
            //        password: this.mqttpassword,
            //        timeout: 3,
            //        useSSL: true,
            //        cleanSession: true,
            //        keepAliveInterval: 60,
            //        reconnect: true,
            //        mqttVersion: 4,
            //        mqttVersionExplicit: true,
            //        will: {
            //            topic: willtopic,
            //            payload: willpayload,
            //            qos: 0,
            //            retain: false
            //        }
            //    };
            //    let url = "wss://coreiothubcn.azure-devices.cn/$iothub/websocket?iothub-no-client-cert=true";
            //    let client = mqtt.connect(url, options);
            //    this.mqttclient = client;
            //    client.on('connect', function () {
            //        console.log('连接服务器成功!!!!!!!!');
            //        //订阅主题，替换productKey和deviceName
            //        client.subscribe('$iothub/methods/POST/directMethod/#', function (err) {
            //            if (!err) {
            //                console.log('主题订阅成功');
            //            } else {
            //                console.log('主题订阅失败')
            //            }
            //        })
            //    })
            //    client.on('error', (error) => {
            //        console.log('连接失败:', error)
            //    })
            //    client.on('message', (topic, msg) => {
            //        console.log(msg.toString())
            //    })
            // },
            // MQTTconnect() {
            //    let that = this;
            //    // 连接选项
            //    let options = {
            //        connectTimeout: 4000, // 超时时间
            //        // 认证信息
            //        //clientId: '4081392a-2c9d-0189-b973-2614545c156a',
            //        //username: 'CoreIoTHub.azure-devices.cn/02aec4f9-e088-db04-673f-e72490a10fbe/api-version=2016-11-14',
            //        //password: 'SharedAccessSignature sr=CoreIoTHub.azure-devices.cn%2Fdevices%2F02aec4f9-e088-db04-673f-e72490a10fbe&sig=3uMBMx5bjY3tsf3RiAHALuHGMu6ARJ%2F5u%2B4jzknTNm0%3D&se=1598424728',
            //        clientId: this.clientID,
            //        username: this.mqttusername,
            //        password: this.mqttpassword,
            //        timeout: 3,
            //        useSSL: true,
            //        cleanSession: true,
            //        keepAliveInterval: 60,
            //        reconnect: true,
            //        mqttVersion: 4,
            //        mqttVersionExplicit: true,
            //    };
            //    let url = "wss://coreiothubcn.azure-devices.cn/$iothub/websocket?iothub-no-client-cert=true";
            //    let client = this.$mqtt.connect(url, options);
            //    console.log(client, 456789);
            //    that.client = client;
            //    client.on('connect', function () {
            //        console.log('连接服务器成功111');
            //        //订阅主题，替换productKey和deviceName
            //        that.info = '连接服务器成功';
            //        client.subscribe('$iothub/methods/POST/directMethod/#', function (err) {
            //            if (!err) {
            //                console.log('主题订阅成功2222');
            //                //console.log("通讯连接成功！")
            //            } else {
            //                console.log('主题订阅失败')
            //                //that.$Message.success("通讯连接失败，请重试！")
            //            }
            //        });
            //        client.on('message', function (topic, message) {
            //            console.log('11111111')
            //            // message is Buffer
            //            console.log('收到消息 主题：' + topic + "  消息：" + message.toString() + "内容是：" + message);
            //            // sessionStorage.setItem('irsBack',message);
            //            if (topic.indexOf("$iothub/methods/POST/directMethod/") != -1) {
            //                //TODO
            //                console.log("主题符合")
            //            } else {
            //                return;
            //            }
            //            //收到特定主题要回复一个空消息
            //            let pubTopic = "$iothub/methods/res/200/?$rid=" + topic.split('=')[1];
            //            client.publish(pubTopic, "", {}, (error) => {
            //                if (!error) {
            //                    console.log("回复成功")
            //                }
            //            })
            //        })
            //    })
            //    client.on('error', (error) => {
            //        console.log('连接失败:', error)
            //    })
            // },
        }
    }
</script>

<style scoped>
    .box {
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }

    .boxone {
        width: 130px;
        height: 70px;
        line-height: 70px;
        display: block;
        float: left;
        border: 1px solid #ccc;
        text-align: center;
        font-size: 18px;
        border-radius: 10%;
        margin-left: 20px;
    }

    .studentlist {
        width: 100%;
        height: 200px;
        float: left;
        margin-top: 30px;
        text-align: left;
    }

    .studentbox {
        width: 110px;
        height: 200px;
        line-height: 50px;
        float: left;
        border: 1px solid #ccc;
        border-radius: 5%;
        margin-left: 20px;
        text-align: center;
    }
</style>
